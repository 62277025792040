import { buildLink } from '@amazeelabs/react-framework-bridge/gatsby';

export default function reshapeStoryData(stories: Array<{}> = []) {
  let newItems: {
    story_read_more_link: string;
    story_image: Object;
    story_title: string;
    story_teaser_text: string;
    story_date: string;
  }[] = [];

  stories.forEach((item, index) => {
    let thisItem = {};
    for (const [key, value] of Object.entries(item)) {
      if (key === 'story_read_more_link') {
        thisItem[key] = buildLink({ href: value, title: 'read more' });
      } else {
        thisItem[key] = value;
      }
    }
    newItems.push(thisItem);
  });

  // Rename story_image -> Story_image so we can use it as an image component
  const newArrayOfObj = newItems.map(
    ({
      story_image: Image,
      story_read_more_link: Link,
      story_title: title,
      story_teaser_text: teaserText,
      story_date: date,
    }) => ({
      Image,
      Link,
      title,
      teaserText,
      date,
    }),
  );

  return newArrayOfObj;
}
